import modalContent from "../../../../tenant-app/src/components/modal-content";
import Button from "../../../components/button";
import companyDetails from "../../components/companyDetails";
import { getjurisdictionList, createCase } from "./services";
import Nodata from "../../assets/svg/nocredit.svg";
import UserIcon from "../../assets/svg/user.svg";
import CompanyIcon from "../../assets/svg/company.svg";
import DeleteIcon from "../../assets/svg/delete.svg";
// import EditIcon from "../../assets/svg/edit_line.svg";
import Plus from "../../assets/svg/plus.svg";
import { deleteManualData } from "../../services";
import MainEntity from "../../common/main-entity";
import PersonCompanyTab from "../../components/Tabs/person-company.vue";
import Loader from "@/components/loader";
import XMark from "@shared/assets/lightray/icons/x-mark.svg"

export default {
  name: "lightray-searched-records",
  components: {
    "company-details": companyDetails,
    "modal-content": modalContent,
    "v-button": Button,
    Nodata,
    CompanyIcon,
    UserIcon,
    DeleteIcon,
    // EditIcon,
    Plus,
    "main-entity": MainEntity,
    "person-company-tab": PersonCompanyTab,
    Loader: Loader,
    XMark,
  },
  data() {
    return {
      companyKeyword: null,
      companyNumberKeyword: null,
      loadingResult: false,
      loadingResultCompNumber: false,
      allJurisdictionData: null,
      jurisdictionSelection: null,
      mainColor: "",
      addbutton: false,
      main_entity: [],
      selectedValue: null,
      mainEntityLoader: false,
      showMainEntityMoreList: false,
    };
  },
  computed: {
    searchQuery() {
      return this.$route.query;
    },
    mainEntityData() {
      return this.main_entity;
    },
    entitiesListLoader() {
      return this.$store.getters.getEntitiesListLoader;
    },
    entitiesList() {
    return this.$store.getters.getEntitiesList;
  },
  isAnyEntityAvailable() {
    return this.$store.getters.getEntitiesList.length > 0;
  },
  },
  async mounted() {
    await this.jurisdictionList();
    await this.$store.dispatch("getEntitiesList");
    // this.getMainEntity();
  },
  methods: {
    toggleMoreList(value) {
      this.showMainEntityMoreList = value;
    },
    personSearch() {
      this.$router.push({
        name: "add-person",
        params: { type: "person", mode: "add" },
        query: {
          package_id: this.$route.query.package_id,
          client_id: this.$route.query.client_id,
          t: new Date().getTime(),
          type: "person",
          mode: "add",
        },
      });
    },
    companySearch() {
      this.$router.push({
        name: "search-company-records",
        params: { type: "company-search" },
        query: {
          package_id: this.$route.query.package_id,
          client_id: this.$route.query.client_id,
          type: "company",
          mode: "add",
        },
      });
    },
    async deleteEntity(entity_id) {
      try {
        let deleteData = await deleteManualData(entity_id);
        if (deleteData) {
          if (this.entitiesList.length == 1) {
            sessionStorage.removeItem("case_name");
            this.$forceUpdate();
            this.$router.push({
              name: "add-multientities",
              query: {
                package_id: this.$route.query.package_id,
                client_id: this.$route.query.client_id,
                t: new Date().getTime(),
              },
            });
          } else {
            this.entitiesList.sort(
              (a, b) => new Date(a.updated_at) - new Date(b.updated_at)
            );
            if (this.entitiesList.length) {
              sessionStorage.setItem(
                "case_name",
                this.entitiesList[0]?.company_name || this.entitiesList[0]?.person
              );
            }
          }
          this.$toast.success("Deleted Successfully");
          await this.$store.dispatch("getEntitiesList");
          // remove the more list option form entity ribbon
          if (this.entitiesList.length < 5) {
            this.showMainEntityMoreList = false;
          }
        } else {
          this.$toast.warning("Automated Entity is not deleted");
        }
      } catch (err) {
        this.$toast.error("Entity is not deleted");
      }
    },
    // async getMainEntity() {
    //   this.mainEntityLoader = true;
    //   let data = this.getCaseFromSessionStorage();
    //   if (data.neo_case_id) {
    //     let case_id = data.neo_case_id;
    //     let main_entity = await getManualData(case_id);
    //     let company = main_entity.data.company.map((i) => ({
    //       ...i,
    //       type: "company",
    //     }));
    //     let person = main_entity.data.person.map((i) => ({
    //       ...i,
    //       type: "person",
    //     }));
    //     main_entity = [...company, ...person];
    //     this.main_entity = main_entity;
    //     this.mainEntityLoader = false;
    //   } else {
    //     this.main_entity = [];
    //     this.mainEntityLoader = false;
    //   }
    // },
    handleChange() {
      this.$emit("input", this.jurisdictionSelection);
    },
    clearSelection() {
      this.jurisdictionSelection = null;
      this.handleChange();
    },
    addManuallyCompany() {
      this.$router.push({
        name: "add-person",
        params: { type: "company", mode: "add" },
        query: {
          package_id: this.$route.query.package_id,
          client_id: this.$route.query.client_id,
          type: "company",
          mode: "add"
        },
      });
    },
    saveCaseInSessionStorage(data) {
      sessionStorage.setItem("id", data.id);
      sessionStorage.setItem("neo_case_id", data.neo_case_id);
    },
    getCaseFromSessionStorage() {
      return {
        id: sessionStorage.getItem("id"),
        neo_case_id: sessionStorage.getItem("neo_case_id"),
      };
    },
    // This is for  when credit
    // openModal() {
    //     this.$refs["credit-less"].showModal();
    // },
    async searchCaseCompany(type) {
      try {
        if (type == "companyNumber" && !this.companyNumberKeyword) {
          this.$toast.error("Please enter company number");
          return;
        }
        if (type == "companyName" && !this.companyKeyword) {
          this.$toast.error("Please enter company name");
          return;
        }
        if (type == "companyName") this.loadingResult = true;
        else this.loadingResultCompNumber = true;

        const searchedCompany =
          type == "companyName"
            ? this.companyKeyword
            : this.companyNumberKeyword;
        const casePayload = { package_id: this.$route.query.package_id };
        let data = this.getCaseFromSessionStorage();
        if (!data.id || !data.neo_case_id) {
          const res = await createCase(casePayload);
          data = res.data;
          this.saveCaseInSessionStorage(data);
        }
        this.loadingResult = false;
        this.loadingResultCompNumber = false;
        // this.$router.push({
        //   name: "domain-records",
        //   params: { type: "domain-records" },
        //   query: {
        //     name: searchedCompany,
        //     jurisdiction: this.jurisdictionSelection,
        //     case_id: data.neo_case_id,
        //     neo_uuid: data.id,
        //   },
        // });
        this.$router.push({
          name: "company-records",
          params: { type: "company-search" },
          query: {
              name: searchedCompany,
              jurisdiction: this.jurisdictionSelection,
              case_id:  data.neo_case_id,
              neo_uuid:  data.id,
              skip: false
          }
      })
        // let custmPayload = { case_name: searchedCompany, neo_case_id: data.id }
        // await updateCaseName(custmPayload,data.neo_case_id)
      } catch (err) {
        console.error("err", err);
        this.$toast.error("Something went wrong.");
        this.loadingResultCompNumber = false;
        this.loadingResult = false;
      }
    },
    async jurisdictionList() {
      try {
        const result = await getjurisdictionList();
        this.allJurisdictionData = result.data.data;
      } catch (err) {
        console.error("err", err);
        this.$toast.error(err?.response?.data?.detail);
      }
    },
    // goBack() {
    //   this.$router.push({
    //     name: "add-multientities",
    //     // params: { mode: "save" },
    //     query: {
    //       package_id: this.$route.query.package_id,
    //       client_id: this.$route.query.client_id,
    //       t: new Date().getTime(),
    //     },
    //   });
    // },
    goBack() {
      if (this.isAnyEntityAvailable) {
        this.$router.push({
          name: "select-features",
          query: {
            package_id: this.$route.query.package_id,
            client_id: this.$route.query.client_id,
            t: new Date().getTime(),
            mode: "features",
          },
        });
      } else {
        this.$router.push({
          name: "add-multientities",
          query: {
            package_id: this.$route.query.package_id,
            client_id: this.$route.query.client_id,
            t: new Date().getTime(),
          },
        });
      }
      this.personData = {
        first_name: "",
        middle_name: "",
        last_name: "",
        gender: "",
        date_of_birth: "",
      };
      this.companyData = {
        company_name: "",
        company_number: "",
        inc_date: "",
        date_of_dissolution: "",
        jurisdiction: "",
      };
    },
  },
  created() {
    const rootStyles = getComputedStyle(document.documentElement);
    this.mainColor = rootStyles.getPropertyValue("--theme-color-main");
  },
};
