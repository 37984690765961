import UserIcon from "../../../assets/svg/user.svg";
import CompanyIcon from "../../../assets/svg/company.svg";
import modalContent from "../../../../../tenant-app/src/components/modal-content"
import Button from "../../../../components/button"
import { getPositions } from "../../../services";

export default {
    name: "select-entities",
    data: function () {
        return {
            // officerRows: [],
            // ownershipRows: [],
            // previousNamesRows: [],
            officerColumns: [
                {
                    label: "Name",
                    field: "name",
                    sortable: true
                },
                {
                    label: "Position",
                    field: "position",
                    sortable: true
                },
                {
                    label: "Status",
                    field: "inactive",
                    sortable: true
                },
                {
                    label: "Start Date",
                    field: "start_date",
                    sortable: true
                },
                {
                    label: "End Date",
                    field: "end_date",
                    sortable: true
                }],
            ownershipColumns: [{
                label: "Name",
                field: "name"
            }, {
                label: "Type",
                field: "type"
            },
            {
                label: "Ownership Type",
                field: "owner_type"
            },
            {
                label: "Status",
                field: "owner_status"
            },],
            previousNamesColumns: [{
                label: "Name",
                field: "company_name"
            }, {
                label: "Start Date",
                field: "start_date"
            }, {
                label: "End Date",
                field: "end_date"
            }],
            selectedData: {},
            tradingData:{
                company_name:"",
                company_number:"",
                jurisdiction:"",
                start_date:"",
                end_date:"",
            },
            officerData:{
                first_name:"",
                middle_name:"",
                last_name:"",
                dob:"",
                position:"",
                inactive:"",
                start_date:"",
                end_date:""
            },
            ownershipData:{
                company_name:"",
                first_name:"",
                middle_name:"",
                last_name:"",
                jurisdiction:[{name:"",code:""}],
                company_number:"",
                dob:"",
                person_type:"",
                company_type:"",
                status:"",
                start_date:"",
                end_date:""
            },
            positions:[],
            mainColor:"",
            allJurisdiction:[]
        }
    },
    props: {
        officerRows: Array,
        ownershipRows: Array,
        previousNamesRows: Array,
        loader:Boolean
    },
    components: {
        UserIcon,
        CompanyIcon,
        "modal-content": modalContent,
        Button
    },
    computed: {
    },
    created(){
        const rootStyles = getComputedStyle(document.documentElement);
        this.mainColor = rootStyles.getPropertyValue("--theme-color-main");
    },

    methods: {
        handleEntitiesSelection(type, data) {
            switch (type) {
              case "officer":
                // this.selectedData["officer"] = data.selectedRows;
                data.selectedRows.forEach((item)=>{
                    this.officerRows[item.originalIndex] =  item;
                })
                break;
              case "ownership":
                // this.selectedData["ownership"] = data.selectedRows;
                data.selectedRows.forEach((item)=>{
                    this.ownershipRows[item.originalIndex] =  item;
                })
                break;
              case "previousNames":
                // this.selectedData["previous_company"] = data.selectedRows;
                data.selectedRows.forEach((item)=>{
                    this.previousNamesRows[item.originalIndex] =  item;
                })
                break;
            }
          },
          OfficerModal(type){
            this.$refs[type].showModal();
          },
          closeModal(type){
            if(type=='previousModal'){
                this.tradingData={
                    company_name:"",
                    company_number:"",
                    jurisdiction:"",
                    start_date:"",
                    end_date:"",}
            }
            else if(type=='officerModal'){
                this.officerData={
                    first_name:"",
                    middle_name:"",
                    last_name:"",
                    dob:"",
                    position:"",
                    inactive:"",
                    start_date:"",
                    end_date:""
                }
            }
            else if(type=='ownershipModal'){
                this.ownershipData={
                    first_name:"",
                    middle_name:"",
                    last_name:"",
                    dob:"",
                    person_type:"",
                    company_type:"",
                    status:"",
                    start_date:"",
                    end_date:""
                }
            }
            this.$refs[type].hideModal();
          },
          submitTradingData(type){
            if(this.tradingData.company_name ==""){
                this.$toast.error("Please fill atleast company name")
                return
            }else{
            this.previousNamesRows.push(this.tradingData)
            this.$refs[type].hideModal();
            this.tradingData={
                company_name:"",
                company_number:"",
                jurisdiction:"",
                start_date:"",
                end_date:"",}
            }
          },
          submitOfficerdata(type){
            if(this.officerData.first_name =="" || this.officerData.last_name ==""){
                this.$toast.error("Please fill mandatory fields")
                return
            }else{
            let updatedData={
               name:this.officerData?.first_name+" "+this.officerData?.middle_name+" "+this.officerData?.last_name,
               position:this.officerData.position,
               inactive:this.officerData.inactive,
               start_date:this.officerData.start_date,
               end_date:this.officerData.end_date
            }
            this.officerRows.push(updatedData)
            this.$refs[type].hideModal();
            this.officerData={
                first_name:"",
                middle_name:"",
                last_name:"",
                dob:"",
                position:"",
                status:"",
                start_date:"",
                end_date:""
            }
            }
          },
          ownershipDetail(){
            this.ownershipData={
                company_name:"",
                first_name:"",
                middle_name:"",
                last_name:"",
                jurisdiction:[{name:"",code:""}],
                company_number:"",
                dob:"",
                person_type:"",
                company_type:"",
                owner_type:"",
                status:"",
                start_date:"",
                end_date:""
            }
          },
          submitOwnerData(type){
            if(this.ownershipData.company_type=='entityStatement') {
                if(this.ownershipData.company_name ==""){
                    this.$toast.error("Please fill atleast company name")
                    return
                }else{
                    let updatedData={
                        name:this.ownershipData?.company_name,
                        type:this.ownershipData.company_type,
                        owner_type:this.ownershipData.owner_type,
                        owner_status:this.ownershipData.status,
                        jurisdiction:this.ownershipData.jurisdiction
                     } 
                     this.ownershipRows.push(updatedData)
            }
        }
            else {
                if(this.ownershipData.first_name =="" || this.ownershipData.last_name == ""){
                    this.$toast.error("Please fill mandatory fields")
                    return
                }else{
                    let updatedData={
                        name:this.ownershipData?.first_name+" "+this.ownershipData?.middle_name+" "+this.ownershipData?.last_name,
                        type:this.ownershipData.company_type,
                        owner_type:this.ownershipData.owner_type,
                        owner_status:this.ownershipData.status
                     } 
                     this.ownershipRows.push(updatedData)
            }
        }
            this.$refs[type].hideModal();
            this.ownershipData={
                first_name:"",
                company_name:'',
                middle_name:"",
                last_name:"",
                dob:"",
                person_type:"",
                company_type:"",
                status:"",
                start_date:"",
                end_date:""
            }

          },
    },
    async mounted() {    
        let data=await getPositions();
        this.positions=data.data.data
        let datas= localStorage.getItem("SelectedCompany")
        datas = JSON.parse(datas);
        this.allJurisdiction=datas.jurisdictionData
        // this.officerRows = this.data.officersData;
        // this.ownershipRows = this.data.ownershipData;
        // this.previousNamesRows = this.data.previousData;
    }
}