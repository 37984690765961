<template>
    <div>
        <div class="flex mt-8 mb-4 justify-between items-center">
            <div class="flex justify-between items-center">
                <h2 class="table_label "> {{ `Corporates Records (${this.rowsData?.length || 0})` }}</h2>
                <p class="text-gray-400 mx-2">
                    {{`(Retrieved ${this.rowsData?.length} out of ${this.totalCount} records)`}}
                </p>
                <Button @click="fetchMore()" style="padding: 5px 15px;" :outline="true" text="Fetch more"
                    class="button_red_outline" :disabled="fetchDisabled" :loader="this.loadingData"></Button>
            </div> 
            <div class="flex items-center">
                <input type="checkbox" class="skipCheckbox" name="skipCorporate" v-model="skipCorporate"
                    @click="handleSkip" />
                <p class="font-small text-sm mt-1 faded">The required Corporates Records is missing in the list below.
                </p>
            </div>
        </div>
        <div class="max-w-full">
            <vue-good-table 
                :key="tableKey"
                theme="polar-bear" 
                styleClass="vgt-table bordered " 
                max-height="60vh" 
                :rows="tableData"
                :isLoading="loadingData" 
                :columns="columns" 
                :fixed-header="true" 
                ref="corporateTable" 
                :class="{ 'disabled': skipCorporate }"
                @on-selected-rows-change="handleSelection($event)"
                :select-options="{ enabled:  !skipCorporate}"
                :pagination-options="{ enabled: true, mode: 'records', perPage: 5 , dropdownAllowAll: false,}"
                :sort-options="{
                    enabled: true,
                    initialSortBy: {field: 'inactive', type: 'asc'}
                }"
            >
                <template slot="table-row" slot-scope="props" style="font-size: 10px !important;">
                    <div v-if="props.column.field == 'previous_names'">
                        <span>{{ getPreviousNames(props.row.previous_names) }}</span>
                    </div>
                    <div v-else-if="props.column.field == 'inactive'">
                        <!-- <span :class="props.row.inactive ? 'text-red-500' : 'text-green-500'">{{ props.row.inactive? 'Inactive' : 'Active' }} </span> -->
                        <span class='text-red-500' v-if="props.row.inactive === true"> Inactive</span>
                        <span class='text-green-500' v-else-if="props.row.inactive === false"> Active</span>
                        <span class='text-gray-500' v-else-if ="props.row.inactive === 'unknown'" >Unknown</span>
                    </div>
                    <!-- <div v-else-if="props.column.field == 'selection'">
                        <div style="text-align: center;">
                            <input type="radio" class="radio_button" @click="setSelectedRow(props, 'corporate_records')"
                                name="row-selection-radio" :checked="props.row.selected" :disabled="skipCorporate" />
                        </div>
                    </div> -->
                    <div v-else-if="props.column.field == 'name'" class="check-fixed-olumn">
                        <span class="break-words  inline-block" style="max-width: 90% !important;">{{
                            props.row.name }}</span>
                        <font-awesome-icon @click="handleCompanyDetails(props, skipCorporate); $event.stopPropagation()"
                            class="cursor-pointer text-right theme_color" style="float:right; font-size: 0.875rem;"
                            icon="info-circle" />
                    </div>
                </template>
                <template slot="table-column" slot-scope="props">
                    <div v-if="props.column.label === 'Jurisdiction'" class="w-full flex  align-center">
                        <div> {{ props.column.label }}</div>
                        <!-- <div class="mx-2 cursor-pointer" @click="mapModal(e)">
                            <svg xmlns="http://www.w3.org/2000/svg" height="1rem"
                                viewBox="0 0 384 512">
                                ! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc.
                                <path
                                    d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" />
                            </svg>
                        </div> -->
                    </div>
                    <div v-else>
                        {{ props.column.label }}
                    </div>
                </template>
            </vue-good-table>
        </div>
        <!--Company details modal-->
        <div>
            <modal-content ref="company-records" title="Company Details" name="records" :showFooter="false">
                <template #content>
                    <companyDetails :jurisdictionData="jurisdictionData" :companyDetails="companyDetails"
                        :showPrintButton="true" />
                </template>
            </modal-content>
        </div>
        <!--map modal-->
        <div>
            <modal-content ref="map" title="Map" name="map" :showFooter="false">
                <template #content>
                    <div class="">
                        <div id="map" class="h-80 "></div>
                    </div>
                </template>
            </modal-content>
        </div>
        <div class="mt-4 flex justify-between">
            <div class="">
                <Button @click="goBack()" style="padding: 14px 60px;" :outline="true" text="Back"
                    class="button_red_outline"></Button>
            </div>
            <div class="">
                <Button style="padding: 14px 60px;" class="button_red" @click="handleRecordsSelection()" text="Next">
                </Button>
            </div>
        </div>
    </div>
</template>
<script>
import modalContent from "../../../../tenant-app/src/components/modal-content"
import companyDetails from "../../components/companyDetails"
import { getCompanyDetails, getCompanySearchQueryId, getjurisdictionList } from "../search-records/services"
import Button from "../../../components/button"
// import { makeCompanyGraph, mergeAllNodeWithCentralnode, onSearch } from "../../components/graph/helpers"
// import { insertGraphNodes } from "../../services"
export default {
    name: "searched-records",
    data: function () {
        return {
            google:{},
            columns: [
            // {
            //     label: "Selection",
            //     field: "selection",
            //     sortable: false,
            // },
            {
                label: "Company Name",
                field: "name",
                width: "250px",
                filterOptions: {
                    styleClass: 'class1',
                    enabled: true,
                    placeholder: 'Select',
                    // filterValue: 'Jane', 
                    filterDropdownItems: [],
                    // filterFn: this.columnFilterFn, 
                    trigger: 'enter',
                },
            },
            {
                label: "Jurisdiction",
                field: "jurisdiction",
                filterOptions: {
                    styleClass: 'class1',
                    enabled: true,
                    placeholder: 'Select',
                    // filterValue: 'Jane', 
                    filterDropdownItems: [],
                    // filterFn: this.columnFilterFn, 
                    trigger: 'enter',
                },
            }, 
            {
                label: "Company Reg.No.",
                field: "company_number",
                filterOptions: {
                    styleClass: 'class1',
                    enabled: true,
                    placeholder: 'Select',
                    // filterValue: 'Jane', 
                    filterDropdownItems: [],
                    // filterFn: this.columnFilterFn, 
                    trigger: 'enter',
                },
            }, 
            {
                label: "Address",
                field: "registered_address_in_full",
                width: "250px",
                filterOptions: {
                    styleClass: 'class1',
                    enabled: true,
                    placeholder: 'Select',
                    // filterValue: 'Jane', 
                    filterDropdownItems: [],
                    // filterFn: this.columnFilterFn, 
                    trigger: 'enter',
                },
            }, 
            {
                label: "Company Type",
                field: "company_type",
                filterOptions: {
                    styleClass: 'class1',
                    enabled: true,
                    placeholder: 'Select',
                    // filterValue: 'Jane', 
                    filterDropdownItems: [],
                    // filterFn: this.columnFilterFn, 
                    trigger: 'enter',
                },
            }, 
            {
                label: "Incorporation Date",
                field: "incorporation_date",
                sortable: false,
                filterOptions: {
                    styleClass: 'class1',
                    enabled: true,
                    placeholder: 'Select',
                    // filterValue: 'Jane', 
                    filterDropdownItems: [],
                    // filterFn: this.columnFilterFn, 
                    trigger: 'enter',
                },
            }, 
            {
                label: "Previous/ Trading Names",
                field: "previous_names",
                filterOptions: {
                    styleClass: 'class1',
                    enabled: true,
                    placeholder: 'Select',
                    // filterValue: 'Jane', 
                    filterDropdownItems: [],
                    // filterFn: this.columnFilterFn, 
                    trigger: 'enter',
                },
            }, 
            {
                label: "Status",
                field: "inactive",
                filterOptions: {
                    styleClass: 'class1',
                    enabled: true,
                    placeholder: 'Select',
                    filterDropdownItems: [], 
                    trigger: 'enter',
                },
            }],
            skipCheck: false,
            skipCorporate: false,
            companyDetails: null,
            companyDetailsData: null,
            officersData: null,
            ownershipData: null,
            previousNamesData: null,
            loadingData: true,
            jurisdictionData: null,
            companyData: null,
            rowsData: null,
            rowsDetailData: null,
            selectedRow: null,
            selectedDomainRow: null,
            requestId: null,
            selectedCompanyData: null,
            center: { margin: 'auto', width: '100%' },
            selectedData:{},
            page:1,
            totalCount:0,
            tableKey: 0,
        }
    },
    components: {
        "modal-content": modalContent,
        companyDetails,
        Button
    },
    async mounted() {
        await this.jurisdictionList();
        await this.getCompanyData();
        this.loadingData = false;
        this.google = window.google;
    },
    computed: {
        tableData(){
         return this.rowsData
        },
        fetchDisabled(){
        //   console.log(this.page , Math.ceil(this.totalCount/this.rowsData?.length))  
          if(this.page >= Math.ceil(this.totalCount/100))
             { return true}
          else 
             { return false}    
        },
        searchedQuery() {
            return this.$route.query
        },
    },
    methods: {
        fetchMore(){
            this.page++;
            this.resetFilterDropdownItems();
            this.getCompanyData();
            
        },
        resetFilterDropdownItems() {
          this.columns.forEach((column) => {
            if (column.filterOptions) {
              column.filterOptions.filterDropdownItems = [];
              column.filterOptions.filterValue = null;
            }
          });
          this.tableKey++;
        },
        handleSelection(data){
            this.selectedData =  data.selectedRows;
            this.selectedRow = data.selectedRows[0]
        },
        async mapModal() {
            this.$refs["map"].showModal();
            // let address = "New Delhi";
            // let geocoder =  new this.google.maps.Geocoder();
            // console.log("Geocoder",geocoder);
            // geocoder.geocode({ address: address }, function (results) {
            //     console.log(results);
            //     if (results.length > 0) {
            //         const lat = results[0].geometry.location.lat();
            //         const lng = results[0].geometry.location.lng();
            //         const uluru = { lat: lat, lng: lng };
            //         const map = new this.google.maps.Map(document.getElementById("map"), {
            //             zoom: 12,
            //             center: uluru,
            //         });
            //        const marker =new this.google.maps.Marker({
            //             position: uluru,
            //             map: map,
            //         });
            //         console.log(marker)
            //     }
            // });
            let map;
            const position = { lat: -25.344, lng: 131.031 };
            const { Map } = await this.google.maps.importLibrary("maps");
            const { AdvancedMarkerElement } = await this.google.maps.importLibrary("marker");
            map = new Map(document.getElementById("map"), {
                zoom: 4,
                center: position,
                mapId: "DEMO_MAP_ID",
            });

            // The marker, positioned at Uluru
            const marker = new AdvancedMarkerElement({
                map: map,
                position: position,
                title: "Uluru",
            });
            console.log(marker)
        },
        async getCompanyData() {
            try {
                this.loadingData = true;
                let sourcePayload = { name: this.searchedQuery.name, sources: ["opencorporates"], code: this.searchedQuery.jurisdiction }
                let case_id = { neo_case_id: this.searchedQuery.case_id };
                sessionStorage.setItem("neo_case_id",this.searchedQuery.case_id);
                let queryIdResult = await getCompanySearchQueryId(sourcePayload, case_id)
                if(queryIdResult.status==401){
                    this.$toast.error(queryIdResult.data.detail)
                }
                let companySearchPayload = { 
                    name: this.searchedQuery.name, 
                    request_id: queryIdResult.data.request_id,
                    code:this.searchedQuery.jurisdiction,
                    page: this.page,
                    per_page: 100,
                }
                this.requestId = queryIdResult.data.request_id
                await this.$store.dispatch('setSearchData', { companySearchPayload });
                companySearchPayload["code"] = this.searchedQuery.jurisdiction;
                let companyAllData = this.$store.getters.getCompanyAllData;
                this.totalCount = this.$store.getters.getCompanyAllcount
                const { companySearchData } = companyAllData;
                let companyData = companySearchData.map(res => ({...res.company ,doc_id : res.doc_id }));
                companyData.forEach((res) => {
                    const data = this.jurisdictionData.filter(r => r.value == res.jurisdiction_code);
                    res.jurisdiction = data[0]?.label;
                    res.selected = false;
                })
                companyData =  companyData.map(company => {
                    if (company.inactive === null) {
                      return { ...company, inactive: 'unknown' };
                    } else {
                      return { ...company };
                    }
                })
                this.rowsData = companyData;
                this.companyData = companySearchData
                // add filter details
                this.columns.map((column, i) => {
                    if (i !== 0 && column.label !== 'Status') {
                        const items = this.rowsData.map(item => item[column.field])
                        column.filterOptions.filterDropdownItems = [...new Set(items.filter(item => !!item))]
                    } 
                    if (column.label == 'Status') {
                        column.filterOptions.filterDropdownItems = [{ value: false, text: 'Active' }, { value: true, text: 'Inactive' },{ value: 'unknown', text: 'Unknown' }]
                    }
                    if(column.label == 'Previous/ Trading Names'){
                        const items = this.rowsData.map(item => item['previous_names'])
                        let previousNames = items.map(res => ({value :res.map(i => i), text : res.map(i => i.company_name).join() }));
                        previousNames = previousNames.filter(itm => itm.value.length);
                        column.filterOptions.filterDropdownItems = [...new Set(previousNames.filter(item => !!item))]
                    }
                    this.loadingData = false;
                    return column;
                })
                this.$forceUpdate();
            }
            catch (err) {
                console.log('err', err)
                this.$toast.error("No data found");
                this.loadingData = false;
            }
        },
        getPreviousNames(names) {
            let previousNames = names.map(res => res.company_name)
            return previousNames.join()
        },
        setSelectedRow(data, type) {
            if (type == 'corporate_records') {
                this.selectedRow = data.row
            } 
            this.rowsData.forEach((res) => {
                if (res.company_number == this.selectedRow.company_number) {
                    res.selected = true;
                }
                else {
                    res.selected = false;
                }
            })
        },
        handleSkip() {
            this.skipCorporate = !this.skipCorporate
        },
        async handleCompanyDetails(data, selected) {
            const selectedCompanyData = this.companyData.filter(res => res.company.company_number == data.row.company_number);
            const payload = {
                card_type: "company",
                code: data.row.jurisdiction_code,
                doc_id: selectedCompanyData[0].doc_id,
                number: selectedCompanyData[0].company.company_number,
                query_id: this.requestId,
            }
            let case_id=this.searchedQuery.case_id
            const companyDetails = await getCompanyDetails(payload,case_id)
            this.companyDetails = companyDetails.data.data;
            if (selected) {
                this.officersData = this.companyDetails.company?.officers.map(res => res.officer)
                this.previousNamesData = this.companyDetails.company?.previous_names
            }
            else {
                this.$refs["company-records"].showModal();
            }
        },
        goBack() {
            this.$store.dispatch('clearSetSearchData');
            // this.$router.push({
            //     name: "domain-records",
            //     params: { type: "domain-records" },
            //     query: {
            //         name: this.searchedQuery.name,
            //         jurisdiction: this.searchedQuery.jurisdiction,
            //         case_id: this.searchedQuery.case_id,
            //         neo_uuid: this.searchedQuery.neo_uuid,
            //     }
            // })

            this.$router.push({
                name: "search-company-records",
                params: { type: "company-search" }, query: {
                    name: this.searchedQuery.name,
                    jurisdiction: this.searchedQuery.jurisdiction,
                    case_id: this.searchedQuery.case_id,
                    neo_uuid: this.searchedQuery.neo_uuid,
                }
            })
        },
        async handleRecordsSelection() {
            this.$store.dispatch('clearSetSearchData');
            if (this.selectedRow && !this.skipCorporate) {
                localStorage.setItem("SelectedCompany", JSON.stringify({ jurisdictionData: this.jurisdictionData, selectedCompany: this.selectedData , requestId:this.requestId , allCompanyData:this.companyData }))
                this.$router.push({
                    name: "select-entities",
                    params: { type: "select-entities" },
                    query: {
                        queryId: this.requestId,
                        name: this.searchedQuery.name,
                        jurisdiction: this.searchedQuery.jurisdiction,
                        case_id: this.searchedQuery.case_id,
                        package_id: this.searchedQuery.package_id,
                        client_id: this.searchedQuery.client_id,
                        neo_uuid: this.searchedQuery.neo_uuid,
                        number: this.selectedRow.company_number,
                        code: this.selectedRow.jurisdiction_code,
                        doc_id: this.selectedRow.doc_id,
                        skip: this.skipCorporate
                    }
                })
            }
            else if(this.skipCorporate){
                localStorage.setItem("SelectedCompany", JSON.stringify({ jurisdictionData: this.jurisdictionData, selectedCompany: {} , requestId:this.requestId , allCompanyData:[] }))
                this.$router.push({
                    name: "select-entities",
                    params: { type: "select-entities" },
                    query: {
                        queryId: this.requestId,
                        case_id: this.searchedQuery.case_id,
                        name: this.searchedQuery.name,
                        skip: this.skipCorporate,
                        neo_uuid: this.searchedQuery.neo_uuid,
                    }
                })
                
            }
            else {
                this.$toast.error("Please select atleast one corporate record")
            }
        },
        async jurisdictionList() {
            const result = await getjurisdictionList()
            this.jurisdictionData = result.data.data
        },

    },
    async beforeDestroy() {
        this.$store.dispatch('clearSetSearchData');
    },
    async beforeRouteLeave(to, from, next) {
        this.$store.dispatch('clearSetSearchData');
      next();
    },

}
</script>
<style scoped lang="scss">
@import "../../../assets/mixins/mixins.scss";
@import "../../assets/scss/color.scss";

.theme_color {
    color: var(--theme-color-main);
}

.table_label {
    color: #263238;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.skipCheckbox {
    margin-right: 4px;
    margin-left: 12px;
    margin-top: 5px;
}

::v-deep {
    .button_red_outline {
        border-color: $lightray_product_color;
        border-radius: 6px;
        color: $lightray_product_color;
    }

    .button_red_outline:hover {
        border-color: $lightray_product_color;
        background-color: $lightray_product_color;
        color: white;
    }

    .button_red {
        border-color: $lightray_product_color;
        background-color: $lightray_product_color;
        color: white;
    }

    .vgt-wrap__footer {
        padding: 0.5rem 0.75rem !important;
        font-size: 10px !important;
        font-weight: 300 !important;

        label,
        button,
        select {
            font-size: 0.75rem !important;
            font-weight: 500 !important;
        }
    }

    .vgt-table {
        font-size: 10px !important;
        border-radius: 0.25rem !important;
        border: 1px solid #d9d9d9 !important;
    }

    .vgt-table tbody {
        font-size: 10px !important;
        font-weight: bold;
    }

    .vgt-responsive {
        border-radius: 0.25rem;
        border-bottom: 0.15rem solid #d9d9d9 !important;
    }

    .vgt-responsive::-webkit-scrollbar {
        width: 5px !important;
    }

    .vgt-table th {
        background: #F6F8FA !important;
        color: #686A73 !important;
        font-family: Poppins !important;
        font-size: 10px !important;
        font-style: normal !important;
        font-weight: 400 !important;
        line-height: 14px !important;
        letter-spacing: 0.2px !important;
    }

    .vgt-table th:first-child {
        text-align: center !important;
        vertical-align: middle !important;
    }

    .vgt-table .vgt-left-align {
        text-align: left !important;
        vertical-align: middle !important;
    }

    .vgt-table .vgt-left-align input[type="radio"]:checked {
        accent-color: var(--theme-color-main) !important;
    }

    .vgt-table .vgt-checkbox-col input[type="checkbox"]:checked {
        accent-color: var(--theme-color-main) !important;
        background: var(--theme-color-main) !important;
        ;
    }

    .disabled .vgt-table {
        overflow: hidden !important;
        cursor: not-allowed !important;

        // opacity: 0.5 !important;
        span {
            color: #888 !important;
        }
    }

    th.filter-th div select.vgt-select {
        border-radius: 10px !important;
    }
}
</style>
